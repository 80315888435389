//Styles
import './FallbackPage.css'

//Component
export default function FallbackPage() {

    return (
        <div>
            404 page not found
        </div>
    );

}